import React, { useEffect, useState } from 'react'

import { ITrustPilot } from '@/services/trustPilot'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Body2,
  COLORS,
  Caption2,
  Layout,
  TitleLarge2,
  TitleMedium2,
  TitleSmall2,
} from 'ethos-design-system'
import shuffle from 'lodash.shuffle'

import { cmsModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import { trimWithEllipsis } from '../../helpers/words'
import styles from './TrustPilot.module.scss'
import TrustPilotLogo from './TrustPilotLogo'
import { TrustPilotStars } from './TrustPilotStars'

dayjs.extend(customParseFormat)

const REVIEWS_MULTIPLE = 4
const TITLE_LENGTH = 40
const BODY_LENGTH = 120

type TrustPilotReviews = Pick<
  ITrustPilot,
  'trustPilotReviews'
>['trustPilotReviews']

interface PreparedReviewsProps {
  reviewsCount: number
  trustPilotReviews: TrustPilotReviews
}

interface TrustPilotReviewDataProps {
  data: {
    title: string
    text: string
    webLink: string
    stars: number
    date: string
    author: string
  }
  useRebrandColorTheme?: boolean
}

const PreparedReviews = ({
  reviewsCount,
  trustPilotReviews,
}: PreparedReviewsProps): TrustPilotReviews => {
  if (reviewsCount > trustPilotReviews.length) {
    reviewsCount =
      Math.floor(trustPilotReviews.length / REVIEWS_MULTIPLE) * REVIEWS_MULTIPLE
  }

  const allReviews = trustPilotReviews.slice(0, reviewsCount)

  return allReviews
}

interface TrustPilotProps {
  moduleData: {
    heading: string
    showHeader: boolean
    count: number
    experimentKey?: string
    useRebrandColorTheme?: boolean
  }
  trustPilot: ITrustPilot
  settings: Record<string, unknown>
}

const TrustPilot: React.FunctionComponent<TrustPilotProps> = ({
  moduleData,
  trustPilot,
  settings,
}) => {
  const [shuffled, setShuffled] = useState<TrustPilotReviews>([])

  useEffect(() => {
    const newShuffle = shuffle(
      PreparedReviews({
        reviewsCount: moduleData.count,
        trustPilotReviews: trustPilot.trustPilotReviews,
      })
    )
    setShuffled(newShuffle)
  }, [])

  const trustPilotSettings = settings['trust-pilot-reviews.md'] as ITrustPilot
  const trustpilotClasses = [styles.trustPilot]
  const reviewClasses = [styles.reviews]
  if (!moduleData.showHeader) {
    trustpilotClasses.push(styles.noTitleMargin)
    reviewClasses.push(styles.noTitleMargin)
  }
  return (
    <Layout.HorizontallyPaddedContainer>
      <div className={trustpilotClasses.join(' ')}>
        {moduleData.showHeader && (
          <>
            <div className="sm:block md:hidden">
              <TitleMedium2.Serif.Book500>
                {moduleData.heading}
              </TitleMedium2.Serif.Book500>
            </div>
            <div className="hidden md:block">
              <TitleLarge2.Serif.Book500>
                {moduleData.heading}
              </TitleLarge2.Serif.Book500>
            </div>
          </>
        )}
        <div className={reviewClasses.join(' ')}>
          {shuffled.map((review, index) => (
            <TrustPilotReview
              data={review}
              key={`review_${review.title}_${review.stars}_${index}`}
              useRebrandColorTheme={moduleData.useRebrandColorTheme}
            />
          ))}
        </div>
        <div className={styles.bottom}>
          <div>
            <a
              href={trustPilotSettings.trustPilotProfile}
              target="_blank"
              className={styles.allLink}
              rel="noopener noreferrer"
            >
              <Body2.Regular400>See all reviews</Body2.Regular400>
            </a>
          </div>
          <div className={styles.logo}>
            <TrustPilotLogo />
          </div>
        </div>
      </div>
    </Layout.HorizontallyPaddedContainer>
  )
}

const TrustPilotReview: React.FunctionComponent<TrustPilotReviewDataProps> = ({
  data,
  useRebrandColorTheme,
}) => {
  const title = trimWithEllipsis(data.title, TITLE_LENGTH)
  const body = trimWithEllipsis(data.text, BODY_LENGTH)
  const date = dayjs(data.date, 'MM/DD/YYYY').isValid()
    ? data.date
    : dayjs(data.date).format('MM/DD/YYYY')
  const trustPilotReviewClickHandler = ({
    title,
    webLink,
  }: {
    title: string
    webLink: string
  }): void => {
    cmsModuleAnalytics.trustPilotReviewClicked({
      title,
      webLink,
    })
    window.open(webLink, '_blank')
  }
  const colorTheme = [styles.review]
  if (useRebrandColorTheme) {
    colorTheme.push(styles.reviewRebrandColorTheme)
  }
  return (
    <div
      aria-hidden="true"
      className={colorTheme.join(' ')}
      onClick={(): void =>
        trustPilotReviewClickHandler({
          title: data.title,
          webLink: data.webLink,
        })
      }
    >
      <div className={styles.inner}>
        <TrustPilotStars stars={data.stars} />
        <div className={styles.title}>
          <TitleSmall2.Serif.Book500>{title}</TitleSmall2.Serif.Book500>
        </div>
        <div className={styles.body}>
          <Body2.Regular400>{body}</Body2.Regular400>
        </div>
        <div className={styles.meta}>
          <Caption2.Regular400 color={COLORS.GRAY_PRIMARY}>
            <div className={styles.date}>{date}</div>
          </Caption2.Regular400>
          <Caption2.Regular400 color={COLORS.GRAY_SECONDARY}>
            {data.author}
          </Caption2.Regular400>
        </div>
      </div>
    </div>
  )
}

export default TrustPilot
